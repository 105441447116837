import moment from "moment";
import formatNumReporte from "./formatNumReporte";

export const dataEncabezadoPDF = (clienteData, nroReporte) => {
  const dataTelCo = {
    nombre: "Corrientes Telecomunicaciones SAPEM",
    direccion: "Dr. R. Carrillo 444 5to Piso - 3400 - Corrientes",
    condIVA: "I.V.A. Responsable Inscripto",
    cuit: "30716550849",
    fecha: moment().format("DD/MM/YYYY"),
    ingresosBrutos: "30716550849",
    inicioActividades: "09/2019",
  };
  const { cliente, cuit, razon_social } = clienteData;

  return {
    // alignment: "justify",
    margin: [30, 60, 30, 0],
    columns: [
      {
        stack: [
          {
            text: cliente,
            style: "header",
            bold: true,
          },
          {
            text: `CUIT: ${cuit}`,
            style: "header",
          },
          {
            text: razon_social,
            style: "header",
          },
        ],
      },
      {
        stack: [
          {
            image: "wee",
            width: 60,
            absolutePosition: { x: 500, y: 20 },
          },
          {
            text: dataTelCo.nombre,
            style: "header",
            bold: true,
            alignment: "right",
          },
          {
            text: dataTelCo.direccion,
            style: "header",
            alignment: "right",
          },
          {
            text: dataTelCo.condIVA,
            style: "header",
            alignment: "right",
          },
          ...(nroReporte
            ? [
                {
                  text: `Número: ${formatNumReporte(nroReporte)}`,
                  style: "header",
                  alignment: "right",
                },
              ]
            : []),
          {
            text: `CUIT: ${dataTelCo.cuit}`,
            style: "header",
            alignment: "right",
          },
          {
            text: `IIBB Local: ${dataTelCo.ingresosBrutos}`,
            style: "header",
            alignment: "right",
          },
          {
            text: `Inicio de Actividades: ${dataTelCo.inicioActividades}`,
            style: "header",
            alignment: "right",
          },
        ],
      },
    ],
  };
};
